import { BrandCode } from 'common/enums'
import { shareableTextWithUrlUseCase } from 'useCases/mountShareableTextUseCase/mountShareableTextUseCase'

type IShareUseCase = {
  file?: File
  brand?: string
  resellerId?: string
  openShareTextModal: Function
}

export const shareUseCase = async ({
  file,
  brand,
  resellerId,
  openShareTextModal,
}: IShareUseCase) => {
  const shareText = shareableTextWithUrlUseCase({
    chosenBrand: brand as BrandCode,
    resellerId,
  })
  const hasFile = file ? [file] : undefined

  try {
    if (hasFile !== undefined) {
      const imageShareData = { files: hasFile }
      await navigator.share(imageShareData)
      if (shareText) openShareTextModal()
      return
    } else {
      const textShareData = { text: shareText }
      await navigator.share(textShareData)
      return
    }
  } catch (error) {
    return
  }
}
export default shareUseCase
