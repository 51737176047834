import { createContext, useContext, useState } from 'react'

interface StoreProviderProps {
  children: React.ReactNode
}

interface IStoreUpdate {
  uf?: { key: string; value: string }
  brand?: string
  hideUi?: boolean
  fromApp?: boolean
  hasDownloadedCard?: boolean
}

interface IStore {
  uf?: { key: string; value: string }
  brand?: string
  fromApp?: boolean
  hideUi: boolean
  hasDownloadedCard: boolean
  updateStore: (data: IStoreUpdate) => void
}

export const StoreContext = createContext({} as IStore)

const StoreProvider = ({ children }: StoreProviderProps) => {
  const [store, setStore] = useState({
    hideUi: false,
    hasDownloadedCard: false,
  })

  const handleUpdateStore = (data?: IStoreUpdate) => {
    setStore(prev => ({
      ...prev,
      ...data,
    }))
  }

  return (
    <StoreContext.Provider value={{ ...store, updateStore: handleUpdateStore }}>
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider

export const useStore = () => useContext(StoreContext)
