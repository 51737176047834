import html2canvas from 'html2canvas'

const exportComponentToFileUseCase = async (componentToDownload: HTMLDivElement): Promise<File> => {
  window.scrollTo(0, 0)
  const canvas = await html2canvas(componentToDownload as HTMLElement, {
    scrollY: -window.scrollY,
    useCORS: true,
    backgroundColor: null,
  })
  return new Promise((resolve, reject) => {
    let file: File
    canvas.toBlob(
      blob => {
        if (blob === null) return reject('Error creating blob')
        file = new File([blob], 'boticard.png', { type: blob.type })
        if (!file) reject('Error creating file')
        resolve(file)
      },
      'image/png',
      1.0
    )
  })
}

export default exportComponentToFileUseCase
