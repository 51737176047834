import { Box } from '@grupoboticario/flora-react-box'
import { FloraProvider } from '@grupoboticario/flora-react-flora-provider'
import Routes from 'components/routes/Routes'
import { useEffect } from 'react'
import { BrowserRouter as RouterProvider } from 'react-router-dom'
import { saveResellerIdUseCase } from 'useCases'
import { StoreProvider } from 'utils'
import { getSearchParamsSerialized } from 'utils/url'

const App = (): JSX.Element => {
  const { reseller_id } = getSearchParamsSerialized()

  useEffect(() => {
    if (reseller_id) {
      saveResellerIdUseCase(reseller_id)
    }
  }, [reseller_id])

  useEffect(() => {
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document

    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  return (
    <FloraProvider>
      <Box
        css={{
          height: 'calc(var(--vh, 1vh) * 100);',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <StoreProvider>
          <RouterProvider>
            <Routes />
          </RouterProvider>
        </StoreProvider>
      </Box>
    </FloraProvider>
  )
}

export default App
