import { IAddress } from 'common/types/AddressType'

export enum ActionTypes {
  geolocate = 'geolocating',
  fetchAddress = 'fetchingAddress',
  resolve = 'resolved',
  reject = 'rejected',
  idle = 'idle',
}

export type State = {
  error?: string
  isLoading: boolean
  location?: IAddress
  status: ActionTypes
}

export type Action =
  | { type: ActionTypes.geolocate }
  | { type: ActionTypes.fetchAddress }
  | { type: ActionTypes.resolve; data: IAddress }
  | { type: ActionTypes.reject; error: string }
