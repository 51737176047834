import { MutableRefObject, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Box } from '@grupoboticario/flora-react-box'
import { Button } from '@grupoboticario/flora-react-button'
import { Add, ArrowBack, ArrowForward, Download, Share } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useStore } from 'utils'
import exportComponentToFileUseCase from 'useCases/exportComponentToFileUseCase/exportComponentToFileUseCase'
import { shareUseCase, downloadFileUseCase, getResellerIdUseCase } from 'useCases'
import { ModalToShare } from '../ModalToShare'

export interface ControlBarProps {
  onGoBack: () => void
  componentToDownload?: MutableRefObject<HTMLDivElement>
}

const ControlBar = ({ componentToDownload, onGoBack }: ControlBarProps) => {
  const [file, setFile] = useState<File>()
  const [openExitDialog, setOpenExitDialog] = useState(false)
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState(false)

  const { fromApp, brand, hasDownloadedCard, hideUi, updateStore } = useStore()

  const resellerId = getResellerIdUseCase()

  const openShareTextModal = () => {
    setModalOpen(true)
  }

  const history = useHistory()
  const handleViewCard = () => {
    updateStore({ hideUi: true })
  }

  const handleEditCard = () => {
    updateStore({ hideUi: false })
  }

  const goToBrandSelection = () => {
    updateStore({ hideUi: false, brand: fromApp ? brand : undefined })
    history.push('/selecionar-marca')
  }

  const handleCreateNewCard = () => {
    if (!hasDownloadedCard) {
      setOpenExitDialog(true)
    } else {
      goToBrandSelection()
    }
  }

  const handleSaveCard = async () => {
    setDownloadingFile(true)
    if (componentToDownload && file) {
      await downloadFileUseCase(file)
      updateStore({ hasDownloadedCard: true })
    }
    setDownloadingFile(false)
  }

  const handleShareTextAndImage = () => {
    shareUseCase({ file, brand, resellerId, openShareTextModal })
    if (file) {
      updateStore({ hasDownloadedCard: true })
    }
  }

  useEffect(() => {
    if (!hideUi) return
    exportComponentToFileUseCase(componentToDownload!.current).then(convertedFile => {
      setFile(convertedFile)
    })
  }, [componentToDownload, hideUi])

  return (
    <>
      <Dialog open={openExitDialog} onClose={() => setOpenExitDialog(false)}>
        <DialogTitle>Sair sem salvar</DialogTitle>

        <DialogContent>
          Você ainda não fez o download desse card, deseja sair sem salvar?
        </DialogContent>

        <DialogActions>
          <Button onClick={() => goToBrandSelection()} variant="ghost">
            Sim
          </Button>

          <Button onClick={() => setOpenExitDialog(false)}>Não</Button>
        </DialogActions>
      </Dialog>

      <Box
        css={{
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Box
          css={{
            maxWidth: 380,
            width: '100%',
            padding: 4,
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FFF',
          }}>
          <Button
            size="small"
            label=" "
            aria-label="voltar"
            onClick={hideUi ? handleEditCard : onGoBack}
            variant={hideUi ? 'ghost' : 'standard'}
            leftIcon={<ArrowBack fontSize="small" />}>
            {hideUi ? 'Voltar a editar' : ''}
          </Button>

          <Button
            onClick={hideUi ? handleCreateNewCard : handleViewCard}
            size="small"
            variant={hideUi ? 'standard' : 'conversion'}
            {...(hideUi ? { leftIcon: <Add fontSize="small" /> } : null)}
            {...(hideUi ? null : { rightIcon: <ArrowForward fontSize="small" /> })}>
            {hideUi ? 'Criar novo card' : 'Ver meu card'}
          </Button>
        </Box>
      </Box>

      {hideUi && (
        <Box
          css={{
            width: '100%',
            backgroundColor: '#FFF',
            position: 'fixed',
            left: 0,
            bottom: 0,
            padding: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
          <Box
            css={{
              maxWidth: 380,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '$4',
            }}>
            <Button
              isFull
              size="small"
              variant="ghost"
              onClick={handleSaveCard}
              leftIcon={<Download fontSize="small" />}
              isLoading={downloadingFile}>
              {hideUi ? 'Baixar card' : 'Ver meu card'}
            </Button>

            {hideUi &&
            file &&
            navigator.share!! &&
            navigator.canShare?.({
              files: [file],
            }) ? (
              <Button
                isFull
                css={{
                  color: '#222222',
                }}
                size="small"
                variant="conversion"
                onClick={handleShareTextAndImage}
                leftIcon={<Share fontSize="small" />}>
                Compartilhar card
              </Button>
            ) : null}
          </Box>
          <ModalToShare
            modalOpen={modalOpen}
            onClose={setModalOpen}
            onShare={() => shareUseCase({ resellerId, brand, openShareTextModal })}
          />
        </Box>
      )}
    </>
  )
}

export default ControlBar
