import { Box } from '@grupoboticario/flora-react-box'
import BrandSelection from 'components/pages/BrandSelection'
import Home from 'components/pages/Home'
import ProductSelection from 'components/pages/ProductSelection'
import { useCallback, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { RoutesEnum, pagesTitlesMap } from 'utils/routes'
import { tagProvider } from 'infra'

const Routes = () => {
  const history = useHistory()

  const trackPageView = useCallback(() => {
    const pathname = history.location.pathname

    const pageTitle = (pathname && pagesTitlesMap[pathname as RoutesEnum]) || window.document.title

    tagProvider.pageView(pathname, pageTitle)
  }, [history])

  useEffect(() => {
    trackPageView()

    history.listen(trackPageView)
  }, [history, trackPageView])

  return (
    <Switch>
      <Route exact path={RoutesEnum.HOME}>
        <Home />
      </Route>

      <Route path={RoutesEnum.BRAND_SELECTION}>
        <BrandSelection />
      </Route>

      <Route path={RoutesEnum.PRODUCT_SELECTION}>
        <Box css={{ height: '100%', maxWidth: 400 }}>
          <ProductSelection />
        </Box>
      </Route>
    </Switch>
  )
}

export default Routes
