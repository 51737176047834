import { Button } from '@grupoboticario/flora-react-button'
import { Text } from '@grupoboticario/flora-react-text'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { ISelectedUf } from '../UfSelectionDialog/UfSelectionDialog'

export interface UfConfirmationDialogProps {
  onCancel: () => void
  onConfirm: (uf: ISelectedUf) => void
  open: boolean
  ufName: string
  uf: string
}

const UfConfirmationDialog = ({
  onCancel,
  onConfirm,
  open,
  ufName,
  uf,
}: UfConfirmationDialogProps) => (
  <Dialog open={open}>
    <DialogContent sx={{ padding: 2 }}>
      <Text css={{ fontWeight: 500 }}>
        Identificamos que sua localização é: {ufName}. Isso está correto?
      </Text>
    </DialogContent>

    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
      <Button onClick={onCancel} variant="ghost" size="small">
        Não, trocar
      </Button>
      <Button onClick={() => onConfirm({ key: uf.toUpperCase(), value: ufName })} size="small">
        Sim, continuar
      </Button>
    </DialogActions>
  </Dialog>
)

export default UfConfirmationDialog
