export enum BrandCode {
  bot = 'boticario',
  eud = 'eudora',
  oui = 'oui',
  qdb = 'euamomake',
}

export enum BrandNameGTM {
  boticario = 'bot',
  eudora = 'eud',
  oui = 'oui',
  euamomake = 'qdb',
}

export enum DomainBrandUrl {
  BOTICARIO = 'boticario.com.br',
  EUDORA = 'eudora.com.br',
  EAM = 'quemdisseberenice.com.br',
  OUI = 'ouiparis.com',
}
