import { DropdownItem } from '@grupoboticario/flora-react'
import { Button } from '@grupoboticario/flora-react-button'
import { Dropdown } from '@grupoboticario/flora-react-dropdown'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { stateName } from 'common/helpers/stateName'
import { ChangeEvent, useState } from 'react'

export interface ISelectedUf {
  key: string
  value: string
}

export interface UfSelectionDialogProps {
  onConfirm: (uf: ISelectedUf) => void
  open: boolean
}

const ufNames = Object.keys(stateName).map(key => ({ key, value: stateName[key] }))
const initialUf = ufNames[0]

const UfSelectionDialog = ({ onConfirm, open }: UfSelectionDialogProps) => {
  const [selectedUf, setSelectedUf] = useState<ISelectedUf>(initialUf)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.value
    setSelectedUf({ key, value: stateName[key] })
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Selecione seu Estado</DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Dropdown
          id="uf-selection"
          labelText="Estado"
          value={selectedUf.key}
          onChange={handleChange}
          required>
          {ufNames.map(({ key, value }) => (
            <DropdownItem key={key} value={key}>
              {value}
            </DropdownItem>
          ))}
        </Dropdown>
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={() => onConfirm(selectedUf)} isFull>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UfSelectionDialog
