import { Box } from '@grupoboticario/flora-react-box'
import { Container } from '@grupoboticario/flora-react-container'
import { Heading } from '@grupoboticario/flora-react-heading'
import { BrandCode, BrandNameGTM } from 'common/enums'
import Brand from 'components/atoms/Brand'
import GbCardsLogo from 'components/atoms/GbCardsLogo'

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { pushToDataLayer, useStore } from 'utils'

const brands = [
  {
    code: BrandCode.bot,
    color: '#007E78',
    title: 'O Boticário',
  },
  {
    code: BrandCode.eud,
    color: '#613A53',
    title: 'Eudora',
  },
  {
    code: BrandCode.oui,
    color: '#971B2F',
    title: 'O.U.i',
  },
  {
    code: BrandCode.qdb,
    color: '#47413D',
    title: 'Quem Disse, Berenice?',
  },
]

const BrandSelection = () => {
  const history = useHistory()
  const { uf, brand, updateStore } = useStore()

  const handleClick = (brandCode: BrandCode) => {
    updateStore({ brand: brandCode })

    pushToDataLayer({ chosenBrand: BrandNameGTM[brandCode] })
    pushToDataLayer({
      event: 'event',
      eventCategory: 'gbcards:vd',
      eventAction: 'click:button:selecionar-marca',
      eventLabel: `${BrandNameGTM[brandCode]}:${process.env.REACT_APP_GB_CARDS_BASE_URL}/selecionar-produto`,
    })

    history.push('/selecionar-produto')
  }

  useEffect(() => {
    updateStore({ hasDownloadedCard: false })
    if (!uf?.key || !uf.value) {
      history.push('/')
    }

    switch (brand) {
      case BrandCode.bot:
      case BrandCode.eud:
      case BrandCode.oui:
      case BrandCode.qdb:
        history.push('/selecionar-produto')
        break
      default:
        break
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, uf?.key, uf?.value])

  if (brand) {
    return null
  }

  return (
    <Container css={{ paddingTop: 32 }}>
      <GbCardsLogo />

      <Heading level={6} as="h1" css={{ fontWeight: 500, marginTop: 48, marginBottom: 48 }}>
        Qual marca será divulgada nessa imagem?
      </Heading>

      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 8,
        }}>
        {brands.map(({ code, color, title }) => (
          <Brand key={code} code={code} color={color} title={title} onClick={handleClick} />
        ))}
      </Box>
    </Container>
  )
}

export default BrandSelection
