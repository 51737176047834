import { Box } from '@grupoboticario/flora-react-box'
import logo from 'icons/gb_icon.png'

const GbCardsLogo = () => (
  <Box css={{ display: 'flex', alignItems: 'center' }}>
    <img width="100px" src={logo} alt="" />
  </Box>
)

export default GbCardsLogo
