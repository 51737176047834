export enum RoutesEnum {
  HOME = '/',
  BRAND_SELECTION = '/selecionar-marca',
  PRODUCT_SELECTION = '/selecionar-produto',
}

export const pagesTitlesMap: Record<RoutesEnum, string> = {
  [RoutesEnum.HOME]: 'Home VD Cards',
  [RoutesEnum.BRAND_SELECTION]: 'Selecionar Marca',
  [RoutesEnum.PRODUCT_SELECTION]: 'Selecionar Produto',
}
