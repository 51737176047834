import { ChangeEvent, useState } from 'react'
import { Input } from '@grupoboticario/flora-react-input'
import { Text } from '@grupoboticario/flora-react-text'
import { Box } from '@grupoboticario/flora-react-box'
import { useStore } from 'utils'
import AddInfoButton from 'components/atoms/AddInfoButton'
import Overlay from 'components/molecules/Overlay'
import WhatsappIcon from 'icons/WhatsappIcon'
import { BrandCode } from 'common/enums'

const ContactNumber = () => {
  const [open, setOpen] = useState(false)
  const [currentNumber, setCurrentNumber] = useState('')
  const [newNumber, setNewNumber] = useState('')

  const { brand, hideUi } = useStore()

  const buttonColor = brand === BrandCode.bot ? '#007E78' : '#765D98'

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    const numberPattern = /[0-9()-\s]{1,}/
    const sanitizedValue = value.match(numberPattern)?.[0] ?? ''

    const formattedNumber =
      sanitizedValue.length < 3 ? sanitizedValue.replace(/([0-9]{2})/, '($1)') : sanitizedValue

    setNewNumber(formattedNumber)
  }

  const handleConfirm = () => {
    setOpen(false)
    setCurrentNumber(newNumber)
  }

  const handleClose = () => {
    setOpen(false)
    setNewNumber(currentNumber)
  }

  return (
    <>
      <Overlay open={open} onClose={handleClose} onConfirm={handleConfirm}>
        <Input
          id="contact-number"
          labelText="Digite seu número de WhatsApp:"
          value={newNumber}
          onChange={handleChange}
          type="tel"
          required
        />
      </Overlay>

      {Boolean(currentNumber) ? (
        <Text
          color="$light-light"
          {...(hideUi ? null : { onClick: () => setOpen(true) })}
          as="p"
          css={{
            backgroundColor: buttonColor,
            fontSize: 16,
            fontWeight: 500,
            cursor: hideUi ? 'default' : 'pointer',
            padding: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box as="span" css={{ marginRight: 4 }}>
            <WhatsappIcon />
          </Box>
          Peça comigo: {currentNumber}
        </Text>
      ) : (
        <AddInfoButton
          label="adicionar número"
          title="Toque para adicionar seu contato"
          onClick={() => setOpen(true)}
        />
      )}
    </>
  )
}

export default ContactNumber
