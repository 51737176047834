import { BrandCode } from 'common/enums'
import { useStore } from 'utils'
import botiColors from './boticario-colors.json'
import eudoraColors from './eudora-colors.json'
import ouiColors from './oui-colors.json'
import qdbColors from './qbd-colors.json'

const useColors = () => {
  const { brand } = useStore()

  switch (brand) {
    case BrandCode.bot:
      return botiColors
    case BrandCode.eud:
      return eudoraColors
    case BrandCode.oui:
      return ouiColors
    case BrandCode.qdb:
      return qdbColors
    default:
      return botiColors
  }
}

export default useColors
