import { Action, ActionTypes, State } from './types'

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.geolocate:
      return { isLoading: true, status: ActionTypes.geolocate }

    case ActionTypes.fetchAddress:
      return { isLoading: true, status: ActionTypes.fetchAddress }

    case ActionTypes.resolve:
      return { isLoading: false, location: action.data, status: ActionTypes.resolve }

    case ActionTypes.reject:
      return {
        ...state,
        isLoading: false,
        error: 'Error fetching address',
        status: ActionTypes.reject,
      }
  }
}

export default reducer
