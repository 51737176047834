import { useReducer } from 'react'
import { getUfFromStateName } from 'common/helpers/stateName'
import { IAddress } from 'common/types/AddressType'

import { getLocation } from 'api'

import { ActionTypes } from './types'
import reducer from './reducer'

enum GEOLOCATION_ERRORS {
  USER_DENIED_LOCATION = 1,
}

enum ERROR_MESSAGES {
  USER_DENIED_LOCATION = 'User denied location',
}

const useGeolocation = (): {
  handleGetLocation: () => void
  location?: IAddress
  isLoading: boolean
  status: ActionTypes
  error?: string
} => {
  const [state, dispatch] = useReducer(reducer, { isLoading: false, status: ActionTypes.idle })

  const getLocationInfo = async (position: GeolocationPosition) => {
    const { coords } = position
    dispatch({ type: ActionTypes.fetchAddress })

    try {
      const {
        data: { address },
      } = await getLocation(coords.latitude, coords.longitude)

      const uf = getUfFromStateName(address.state)

      if (!uf) {
        dispatch({ type: ActionTypes.reject, error: 'Error fetching location' })
      } else {
        dispatch({ type: ActionTypes.resolve, data: { state: address.state, uf } })
      }
    } catch (error) {
      dispatch({ type: ActionTypes.reject, error: 'Error fetching location' })
    }
  }

  const handleGetLocation = () => {
    dispatch({ type: ActionTypes.geolocate })
    window.navigator?.geolocation?.getCurrentPosition(getLocationInfo, error => {
      if (error.code === GEOLOCATION_ERRORS.USER_DENIED_LOCATION) {
        dispatch({ type: ActionTypes.reject, error: ERROR_MESSAGES.USER_DENIED_LOCATION })
      } else {
        dispatch({ type: ActionTypes.reject, error: 'Error fetching location' })
      }
    })
  }

  return {
    handleGetLocation,
    status: state.status,
    location: state.location,
    isLoading: state.isLoading,
    error: state.error,
  }
}

export default useGeolocation
