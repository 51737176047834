import CurrencyInput from 'react-currency-input-field'

import { Box } from '@grupoboticario/flora-react-box'
import { Text } from '@grupoboticario/flora-react-text'

import { useState } from 'react'
import { useStore } from 'utils'

import Overlay from 'components/molecules/Overlay'
import { BrandCode } from 'common/enums'

export interface PriceControlProps {
  discount: string
  originalAmount: string
}

const brlFormat = (number: number) =>
  Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)

const PriceControl = ({ originalAmount, discount }: PriceControlProps) => {
  const { brand, hideUi } = useStore()

  const parsedOriginalAmount = parseFloat(originalAmount)
  const parsedDiscount = Math.abs(parseFloat(discount))

  const [openOverlay, setOpenOverlay] = useState(false)
  const [values, setValues] = useState({
    amountToCharge: parsedOriginalAmount - parsedDiscount,
    discount: parsedDiscount,
    originalAmount: parsedOriginalAmount,
  })

  const handleClick = () => {
    setOpenOverlay(true)
  }

  const handleChange = (value: string | undefined, name: string | undefined) => {
    if (name && value) {
      setValues(prev => ({
        ...prev,
        [name]: parseFloat(parseFloat(value.replace(',', '.')).toFixed(2)),
      }))
    }
  }

  const handleConfirm = () => {
    setOpenOverlay(false)
    setValues(prev => ({
      ...prev,
      discount: parseFloat((prev.originalAmount - prev.amountToCharge).toFixed(2)),
    }))
  }

  return (
    <>
      <Overlay open={openOverlay} onClose={() => setOpenOverlay(false)} onConfirm={handleConfirm}>
        <Box css={{ display: 'flex', flexDirection: 'column', marginBottom: 32 }}>
          <Text css={{ marginBottom: 24 }}>Qual o valor original desse produto?</Text>
          <CurrencyInput
            aria-label="Valor original"
            prefix="R$"
            decimalsLimit={2}
            id="originalAmount"
            name="originalAmount"
            defaultValue={values.originalAmount.toFixed(2)}
            onValueChange={handleChange}
            inputMode="text"
            type="text"
            style={{
              color: '#FFF',
              fontSize: 32,
              border: 'none',
              borderLeft: '2px solid #FFF',
              paddingLeft: 12,
              backgroundColor: 'transparent',
            }}
          />
        </Box>

        <Box css={{ display: 'flex', flexDirection: 'column' }}>
          <Text css={{ marginBottom: 24 }}>Quanto você quer cobrar?</Text>
          <CurrencyInput
            aria-label="Valor a cobrar"
            prefix="R$"
            decimalsLimit={2}
            id="amountToCharge"
            name="amountToCharge"
            placeholder="Quanto você quer cobrar?"
            defaultValue={values.amountToCharge.toFixed(2)}
            onValueChange={handleChange}
            inputMode="text"
            type="text"
            style={{
              color: '#FFF',
              fontSize: 32,
              border: 'none',
              borderLeft: '2px solid #FFF',
              paddingLeft: 12,
              backgroundColor: 'transparent',
            }}
          />
        </Box>
      </Overlay>

      <Box
        role="button"
        aria-label="Toque para alterar o preço"
        css={{
          cursor: hideUi ? 'default' : 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        {...(hideUi ? null : { onClick: handleClick })}>
        <Text
          css={{ visibility: hideUi ? 'hidden' : 'visible', marginBottom: 4 }}
          size="auxiliarMini">
          Toque para alterar o preço
        </Text>

        <Box css={{ width: '100%', filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.22))' }}>
          {Boolean(values.discount > 0) && (
            <Box
              css={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#E4E6E0',
                color: '#3B2C2F',
                fontSize: 12,
                fontWeight: 500,
                padding: 4,
              }}>
              <Text css={{ marginRight: 4 }}>De:</Text>
              <Text>{brlFormat(values.originalAmount as number)}</Text>
            </Box>
          )}

          <Box
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
              backgroundColor: brand === BrandCode.bot ? '#007E78' : '#765D98',
              color: '#FFF',
              fontSize: 20,
              fontWeight: 500,
              padding: 4,
            }}>
            <Text css={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}>Por:</Text>
            <Text>{brlFormat(values.amountToCharge)}</Text>
          </Box>

          {Boolean(values.discount > 0) && (
            <Box
              css={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: brand === BrandCode.bot ? '#E73C4E' : '#B9904C',
                color: '#FFF',
                fontSize: 20,
                fontWeight: 500,
                padding: 4,
              }}>
              <Text css={{ fontSize: 10, marginBottom: 2 }}>ECONOMIZE</Text>
              <Text>{brlFormat(values.discount as number)}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default PriceControl
