import { Box } from '@grupoboticario/flora-react-box'
import { Button } from '@grupoboticario/flora-react-button'
import { Heading } from '@grupoboticario/flora-react-heading'
import GbCardsLogo from 'components/atoms/GbCardsLogo'

export interface CreateNewCardProps {
  onClick: () => void
}

const CreateNewCard = ({ onClick }: CreateNewCardProps) => (
  <Box
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#364372',
      color: '#FFF',
      padding: '40px 28px',
      borderRadius: 8,
    }}>
    <Box css={{ display: 'flex', justifyContent: 'center' }}>
      <GbCardsLogo />
    </Box>

    <Heading as="p" level={6} css={{ fontWeight: 500, marginBottom: 40, marginTop: 40 }}>
      Crie cards personalizados para compartilhar e divulgar:
    </Heading>

    <Button onClick={onClick} variant="alt" isFull css={{ marginBottom: 130 }}>
      + Criar novo card
    </Button>
  </Box>
)

export default CreateNewCard
