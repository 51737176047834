import { Box } from '@grupoboticario/flora-react-box'
import { Heading } from '@grupoboticario/flora-react-heading'
import { BrandCode } from 'common/enums'
import BoticarioIcon from 'icons/BoticarioIcon'
import EudoraIcon from 'icons/EudoraIcon'
import OuiIcon from 'icons/OuiIcon'
import QdbIcon from 'icons/QbdIcon'

export interface BrandProps {
  code: BrandCode
  color: string
  onClick: (brandCode: BrandCode) => void
  title: string
}

const Brand = ({ code, color, title, onClick }: BrandProps) => {
  const iconSelection = {
    [BrandCode.bot]: <BoticarioIcon />,
    [BrandCode.eud]: <EudoraIcon />,
    [BrandCode.oui]: <OuiIcon />,
    [BrandCode.qdb]: <QdbIcon />,
  }

  return (
    <Box
      role="button"
      onClick={() => onClick(code)}
      css={{
        py: 24,
        px: 16,
        borderRadius: 4,
        color: '#FFF',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: color,
      }}>
      {iconSelection[code]}

      <Heading level={6} css={{ marginTop: 16 }}>
        {title}
      </Heading>
    </Box>
  )
}

export default Brand
