import { urlContentToDataUri } from 'api'
import { IProduct } from 'common/types/ProductType'
import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { useStore } from 'utils'

const getTransparentImageUrl = (product: IProduct | null): string => {
  if (!product?.imageObjects.length) {
    return ''
  }

  const transparentImage = product?.imageObjects.find(imageObject =>
    Boolean(imageObject.transparent)
  )

  return transparentImage?.imageUrl || ''
}

const useSocket = ({ product }: { product: IProduct | null }) => {
  const [response, setResponse] = useState({ partnerSku: '', imageUrl: '' })
  const [imgSrc, setImgSrc] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingSocket, setLoadingSocket] = useState(false)

  const { brand } = useStore()

  const transparentImageUrl = getTransparentImageUrl(product)
  const hasImage = Boolean(transparentImageUrl)

  useEffect(() => {
    if (product?.partnerSku && !hasImage) {
      setLoadingSocket(true)
      const socket = io(process.env.REACT_APP_GB_CARDS_SERVICE_BASE_URL as string, {
        query: {
          partnerSku: product.partnerSku,
          business_unit: brand as string,
        },
      })

      socket.on(`products/${product.partnerSku}`, data => {
        setResponse(data)
        setLoadingSocket(false)
      })

      return () => {
        socket.disconnect()
        setLoadingSocket(false)
      }
    }
  }, [brand, hasImage, product, product?.partnerSku, transparentImageUrl])

  useEffect(() => {
    const getImgUri = async () => {
      try {
        setLoading(true)
        const data = await urlContentToDataUri(transparentImageUrl || response?.imageUrl)
        setImgSrc(data as string)
        setLoading(false)
      } catch (error) {
        setError(true)
      }
    }

    if (product?.partnerSku) {
      getImgUri()
    }
  }, [product?.partnerSku, response?.imageUrl, transparentImageUrl])

  return { imgSrc, error, loadingImage: loading || loadingSocket }
}

export default useSocket
