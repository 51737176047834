import { Button } from '@grupoboticario/flora-react-button'
import { AddCircleOutline } from '@mui/icons-material'
import { useStore } from 'utils'

export interface AddInfoButtonProps {
  label: string
  title: string
  onClick: () => void
}

const AddInfoButton = ({ label, title, onClick }: AddInfoButtonProps) => {
  const { hideUi } = useStore()

  return (
    <Button
      css={{
        borderStyle: 'dashed',
        borderWidth: 1,
        visibility: hideUi ? 'hidden' : 'visible',
        color: 'inherit',
        backgroundColor: 'rgba(98, 108, 195, 0.15)',
        '&:hover': {
          backgroundColor: 'rgba(98, 108, 195, 0.5)',
        },
      }}
      onClick={onClick}
      label={label}
      leftIcon={<AddCircleOutline />}
      size="small">
      {title}
    </Button>
  )
}

export default AddInfoButton
