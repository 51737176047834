import {
  Button,
  Modal as FloraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@grupoboticario/flora-react'

type IModalToShare = {
  modalOpen: boolean
  onClose: (bool: boolean) => void
  onShare: () => void
}

export const ModalToShare = ({ modalOpen, onClose, onShare }: IModalToShare) => {
  return (
    <FloraModal open={modalOpen}>
      <ModalContent aria-label="Modal de compartilhamento">
        <ModalCloseButton tabIndex={1} onClick={() => onClose(false)} />

        <ModalHeader css={{ fontSize: '$xxl', color: '$brand-1', marginTop: '$8' }}>
          Compartilhar Minha Loja Digital
        </ModalHeader>
        <ModalBody>
          <Text css={{ lineHeight: '$base' }} size="bodyLargeShort">
            Gostaria de compartilhar também o seu link personalizado da Minha Loja Digital para
            receber suas vendas organizadas no WhatsApp e na área Gestão de Vendas?
          </Text>
        </ModalBody>
        <ModalFooter css={{ justifyContent: 'flex-start', flexDirection: 'column' }}>
          <Button
            onClick={onShare}
            aria-label="Clique aqui para Compartilhar Minha Loja Digital">
            Compartilhar Minha Loja Digital
          </Button>
          <Button onClick={() => onClose(false)}>Agora não</Button>
        </ModalFooter>
      </ModalContent>
    </FloraModal>
  )
}
