const BoticarioLogo = ({ color }: { color: string }) => (
  <svg width="94" height="12" viewBox="0 0 94 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.1224 1.68591C26.3903 1.68591 24.173 3.98675 24.173 6.84296C24.173 9.67933 26.3903 12 29.1224 12C31.8545 12 34.0719 9.69917 34.0719 6.84296C34.0521 3.98675 31.8347 1.68591 29.1224 1.68591ZM29.1224 11.0083C27.2218 11.0083 26.0933 9.44131 26.0933 6.84296C26.0933 4.22476 27.2218 2.67765 29.1224 2.67765C31.0032 2.67765 32.1317 4.2446 32.1317 6.84296C32.1317 9.44131 31.0032 11.0083 29.1224 11.0083Z"
      fill={color}
    />
    <path
      d="M34.4879 2.79689H38.1109V11.8614H39.9323V2.79689H43.5751V1.80515H34.4879V2.79689Z"
      fill={color}
    />
    <path d="M47.2573 1.80515H45.4161V11.8415H47.2573V1.80515Z" fill={color} />
    <path
      d="M57.1167 9.04448C56.503 10.7304 55.038 10.9883 54.2263 10.9883C52.3257 10.9883 51.1774 9.44118 51.1774 6.84282C51.1774 4.24446 52.3059 2.69735 54.2263 2.69735C55.5923 2.69735 56.6416 3.4114 57.097 4.66099L57.2751 5.15686L58.166 4.7205L58.0274 4.3238C57.4137 2.65768 56.0081 1.70561 54.1669 1.70561C51.415 1.70561 49.2768 3.96678 49.2768 6.86265C49.2768 9.7982 51.3754 11.9999 54.1669 11.9999C56.0279 11.9999 57.4335 11.0478 58.0472 9.40151L58.1858 9.00481L57.2949 8.56845L57.1167 9.04448Z"
      fill={color}
    />
    <path
      d="M77.449 10.0364L76.9144 8.50917C76.7164 7.95379 76.3205 7.4976 75.7859 7.18024C76.7362 6.76371 77.5677 5.97032 77.5677 4.72073C77.5677 2.91576 76.0829 1.82485 73.6082 1.82485H69.8664V11.8613H71.7076V7.59677H73.3112C74.4793 7.59677 74.8554 8.05297 75.192 8.96537L75.7067 10.4133C75.8849 10.9488 76.0631 11.3455 76.2809 11.6629L76.4195 11.8613H78.8942L78.0231 11.048C77.8053 10.8497 77.6073 10.4926 77.449 10.0364ZM71.7076 2.79675H73.4696C75.0138 2.79675 75.6671 3.37196 75.6671 4.72073C75.6671 6.02982 74.994 6.60503 73.4894 6.60503H71.7076V2.79675Z"
      fill={color}
    />
    <path d="M82.0815 1.80515H80.2403V11.8415H82.0815V1.80515Z" fill={color} />
    <path
      d="M89.0505 1.68591C86.3184 1.68591 84.1011 3.98675 84.1011 6.84296C84.1011 9.67933 86.3184 12 89.0505 12C91.7826 12 94 9.69917 94 6.84296C93.9802 3.98675 91.7628 1.68591 89.0505 1.68591ZM89.0505 2.65781C90.9313 2.65781 92.0598 4.22476 92.0598 6.82312C92.0598 9.44131 90.9313 10.9884 89.0505 10.9884C87.15 10.9884 86.0215 9.42148 86.0215 6.82312C86.0017 4.22476 87.15 2.65781 89.0505 2.65781Z"
      fill={color}
    />
    <path
      d="M4.94945 1.68591C2.21735 1.68591 0 3.98675 0 6.84296C0 9.67933 2.21735 12 4.94945 12C7.68155 12 9.89891 9.69917 9.89891 6.84296C9.89891 3.98675 7.68155 1.68591 4.94945 1.68591ZM4.94945 11.0083C3.04886 11.0083 1.92039 9.44131 1.92039 6.84296C1.92039 4.22476 3.04886 2.67765 4.94945 2.67765C6.83025 2.67765 7.95872 4.2446 7.95872 6.84296C7.95872 9.44131 6.83025 11.0083 4.94945 11.0083Z"
      fill={color}
    />
    <path
      d="M20.2729 5.75209C21.2628 5.23638 21.9755 4.36365 21.9755 3.1934C21.9755 1.17025 20.3719 0 17.6002 0H12.3537V11.9405H18.0357C20.7678 11.9405 22.4506 10.6513 22.4506 8.56863C22.4506 7.1802 21.5003 6.24796 20.2729 5.75209ZM17.6596 5.29589H14.3929V0.991739H17.4814C19.2236 0.991739 19.9561 1.64629 19.9561 3.1934C19.9363 4.66118 19.2632 5.29589 17.6596 5.29589ZM14.3929 10.9686V6.28763H17.6398C18.6692 6.28763 20.3719 6.58515 20.3719 8.6083C20.3719 10.2546 19.6195 10.9686 17.8773 10.9686H14.3929Z"
      fill={color}
    />
    <path
      d="M64.8179 0.218256H63.7488L58.6805 11.8613H59.7496L61.2543 8.44969H64.8773L66.4215 11.8613H68.3815L63.9864 2.16206L64.8179 0.218256ZM61.67 7.45795L63.0163 4.36373L64.4219 7.45795H61.67Z"
      fill={color}
    />
  </svg>
)

export default BoticarioLogo
