import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Box } from '@grupoboticario/flora-react-box'
import { Text } from '@grupoboticario/flora-react-text'

import { IColor } from 'components/molecules/BackgroundSelection/BackgroundSelection'
import { useColors, useStore } from 'utils'
import { BrandCode } from 'common/enums'

import BackgroundSelection from 'components/molecules/BackgroundSelection'
import ControlBar from 'components/molecules/ControlBar'
import CalloutText from 'components/organisms/CalloutText'
import AddProduct from 'components/organisms/AddProduct'
import BoticarioLogo from 'icons/BoticarioLogo'
import EudoraLogo from 'icons/EudoraLogo'
import ContactNumber from 'components/organisms/ContactNumber'
import OuiLogo from 'icons/OuiLogo'
import QbdLogo from 'icons/QdbLogo'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Logo: Record<string, any> = {
  [BrandCode.bot]: BoticarioLogo,
  [BrandCode.eud]: EudoraLogo,
  [BrandCode.oui]: OuiLogo,
  [BrandCode.qdb]: QbdLogo,
}

const ProductSelection = () => {
  const colors = useColors()
  const history = useHistory()
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>

  const { uf, brand, fromApp, hideUi, updateStore } = useStore()

  const [selectedBgColor, setSelectedBgColor] = useState(colors[0])

  const BrandLogo = Logo[brand as string]

  const handleGoBack = () => {
    if (fromApp) {
      return
    } else {
      updateStore({ brand: undefined })
      history.push('/selecionar-marca')
    }
  }

  const handleChangeBgColor = (selected: IColor) => {
    setSelectedBgColor(selected)
  }

  useEffect(() => {
    if (!uf || !brand) {
      history.push('/')
    }
  })

  return (
    <Box css={{ position: 'relative', minHeight: '100%', padding: 8, paddingBottom: 60 }}>
      <Box css={{ marginBottom: 32, width: '100%' }}>
        <ControlBar onGoBack={handleGoBack} componentToDownload={containerRef} />
      </Box>

      <Box css={{ position: 'relative', padding: '0 4px', height: '100%' }} ref={containerRef}>
        <Box
          css={{
            padding: '12px 0',
            paddingBottom: 40,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: selectedBgColor.contrastText,
            backgroundColor: selectedBgColor.code,
          }}>
          {hideUi && (
            <Box css={{ paddingTop: 12, marginBottom: 24 }}>
              <BrandLogo color={selectedBgColor.logoColor} />
            </Box>
          )}

          <Box
            css={{
              marginBottom: 16,
              maxWidth: 300,
              padding: '0 8px',
              wordWrap: 'break-word',
              textAlign: 'center',
            }}>
            <CalloutText />
          </Box>

          <Box css={{ marginBottom: 16 }}>
            <AddProduct />
          </Box>

          <Box css={{ marginBottom: 32 }}>
            <ContactNumber />
          </Box>

          {hideUi && (
            <Text
              as="p"
              css={{ fontSize: 16, fontWeight: 500, textAlign: 'center', padding: '0 12px' }}>
              Esta promoção é exclusiva para compras realizadas com o Revendedor que encaminhou este
              card a você. Verifique diretamente com o Revendedor os prazos e condições da promoção.
              Sujeito a disponibilidade de estoque. Essas condições não são válidas para compras
              realizadas nos demais canais de venda da marca O Boticário. Imagem meramente
              ilustrativa.
            </Text>
          )}
        </Box>
      </Box>

      <Box css={{ position: 'absolute', bottom: 8, left: 0, width: '100%' }}>
        <Box css={{ padding: '0px 20px' }}>
          <BackgroundSelection
            colors={colors}
            onClick={handleChangeBgColor}
            selected={selectedBgColor}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProductSelection
