const EudoraProduct = () => (
  <svg
    width="126"
    height="294"
    viewBox="0 0 126 294"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1306 1L19.1183 1.00056C17.4233 1.07797 14.8635 1.56251 12.6618 2.77758C10.4447 4.00117 8.56476 5.98496 8.35615 9.03341C7.9623 14.789 8.19203 29.9779 8.35531 36.838L8.36574 37.2761H43.6601V56.1273L8.7894 59.2237L8.78074 59.2248C7.47087 59.3843 5.53601 59.9989 3.92025 61.329C2.28896 62.6719 1 64.7319 1 67.7301V283.147C1 284.813 1.69866 287.278 3.77838 289.33C5.87131 291.395 9.31979 293 14.7297 293H110.838C116.247 293 119.696 291.395 121.789 289.33C123.869 287.278 124.567 284.813 124.567 283.147V67.7301C124.567 64.7319 123.278 62.6719 121.647 61.329C120.031 59.9989 118.096 59.3843 116.786 59.2248L116.778 59.2237L81.9071 56.1273V37.2761H117.201L117.212 36.838C117.375 29.9779 117.605 14.789 117.211 9.03341C117.002 5.98496 115.123 4.00117 112.905 2.77758C110.704 1.56251 108.144 1.07797 106.449 1.00056L106.437 1H19.1306Z"
      fill="url(#paint0_linear_654_2262)"
      fillOpacity="0.15"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_654_2262"
        x1="1"
        y1="293"
        x2="127.229"
        y2="291.845"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#B9904C" />
        <stop offset="1" stopColor="#786A47" />
      </linearGradient>
    </defs>
  </svg>
)

export default EudoraProduct
