declare global {
  interface Window {
    dataLayer?: Array<unknown>
  }
}

export const pushToDataLayer = (data: unknown) => {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push(data)
}
