import { Box } from '@grupoboticario/flora-react-box'
import { Button } from '@grupoboticario/flora-react-button'
import { Grid } from '@grupoboticario/flora-react-grid'
import { Text } from '@grupoboticario/flora-react-text'
import Overlay from 'components/molecules/Overlay'
import { useState } from 'react'
import { useStore } from 'utils'

export interface IColor {
  name: string
  code: string
  logoColor: string
  contrastText: string
}

export interface BackgroundSelectionProps {
  colors: IColor[]
  onClick: (selected: IColor) => void
  selected: IColor
}

const BackgroundSelection = ({ colors, onClick, selected }: BackgroundSelectionProps) => {
  const [open, setOpen] = useState(false)

  const { hideUi } = useStore()

  const handleClickColor = (color: IColor) => {
    setOpen(false)
    onClick(color)
  }

  return (
    <>
      <Overlay open={open} onClose={() => setOpen(false)}>
        <Text>Cores de fundo</Text>

        <Grid templateColumns="repeat(3, 1fr)" gap="$5" css={{ marginTop: 24 }}>
          {colors.map(color => (
            <Grid key={color.code}>
              <Box
                tabIndex={0}
                aria-label={color.name}
                role="button"
                onClick={() => handleClickColor(color)}
                css={{
                  width: 72,
                  height: 72,
                  padding: 2,
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: selected.code === color.code ? '2px solid #FFFFFF' : 'none',
                }}>
                <Box
                  css={{
                    width: 64,
                    height: 64,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    backgroundColor: color.code,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Overlay>

      <Button
        isFull
        css={{ visibility: hideUi ? 'hidden' : 'visible' }}
        onClick={() => setOpen(true)}>
        Trocar cor de fundo
      </Button>
    </>
  )
}

export default BackgroundSelection
