import { Box } from '@grupoboticario/flora-react-box'
import { Text } from '@grupoboticario/flora-react-text'
import { AddCircleOutline } from '@mui/icons-material'
import { BrandCode } from 'common/enums'
import Anfora from 'icons/Anfora'
import EudoraProduct from 'icons/EudoraProduct'
import { useStore } from 'utils'

export interface AddProductButtonProps {
  onClick: () => void
}

const AddProduct = ({ onClick }: AddProductButtonProps) => {
  const { brand, hideUi } = useStore()

  return (
    <Box
      role="button"
      aria-label="Toque para adicionar um produto"
      onClick={onClick}
      css={{
        visibility: hideUi ? 'hidden' : 'visible',
        position: 'relative',
        width: 'fit-content',
        cursor: 'pointer',
      }}>
      <Box
        css={{
          position: 'absolute',
          top: '30%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <AddCircleOutline />

        <Text css={{ marginTop: 12 }} size="bodyMediumShort" align="center">
          Toque para adicionar um produto
        </Text>
      </Box>

      {brand === BrandCode.bot ? <Anfora /> : <EudoraProduct />}
    </Box>
  )
}

export default AddProduct
