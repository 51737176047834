import { Toaster } from '@grupoboticario/flora-react'

const fileName = 'boticard'

const downloadFileUseCase = async (file: File) => {
  const uri = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.setAttribute('href', uri)
  link.setAttribute('download', `${fileName}.png`)
  const isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

  if (isIos) {
    window.open(uri)
    return
  }

  link.href = uri
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  Toaster.notify({
    kind: 'success',
    description: 'Card baixado com sucesso',
    origin: 'center-top',
  })
}

export default downloadFileUseCase
