import slugify from 'react-slugify'

interface StateNameInterface {
  [key: string]: string
}

const stateName: StateNameInterface = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
}

const stateUf: StateNameInterface = {
  acre: 'ac',
  alagoas: 'al',
  amapa: 'ap',
  amazonas: 'am',
  bahia: 'ba',
  ceara: 'ce',
  'distrito-federal': 'df',
  'espirito-santo': 'es',
  goias: 'go',
  maranhao: 'ma',
  'mato-grosso': 'mt',
  'mato-grosso-do-sul': 'ms',
  'minas-gerais': 'mg',
  para: 'pa',
  paraiba: 'pb',
  parana: 'pr',
  pernambuco: 'pe',
  piaui: 'pi',
  'rio-de-janeiro': 'rj',
  'rio-grande-do-norte': 'rn',
  'rio-grande-do-sul': 'rs',
  rondonia: 'ro',
  roraima: 'rr',
  'santa-catarina': 'sc',
  'sao-paulo': 'sp',
  sergipe: 'se',
  tocantins: 'to',
}

const getUfFromStateName = (state: string): string | undefined => {
  const stateSlug = slugify(state)
  return stateUf[stateSlug]
}

const slugifyStateFromUF = (uf: string): string | undefined => {
  return slugify(stateName[uf])
}

export { stateName, stateUf, getUfFromStateName, slugifyStateFromUF }
