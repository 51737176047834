import Cookies from 'universal-cookie'

const cookies = new Cookies(null, { path: '/' })

export const setCookie = (key: string, value?: string) => cookies.set(key, value)

export const getCookie = (name: string): string | undefined => {
  /** the cookies lib returns undefined as string */
  if (cookies.get(name) === 'undefined' || !name) return
  return cookies.get(name)
}
