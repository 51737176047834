export const QbdLogo = ({ color }: { color: string }) => (
  <svg width="113" height="32" viewBox="0 0 113 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4111 11.5029C13.1522 10.4002 13.5142 9.11824 13.5142 7.65712C13.5694 3.81133 10.6426 0.940771 6.75749 0.992462C2.87237 0.937325 -0.0509428 3.81133 0.000766791 7.65712C-0.0543901 11.5029 2.87237 14.3735 6.75749 14.3183C8.32946 14.3183 9.77388 13.8669 10.877 13.0709L12.3594 14.3183L13.8038 12.6746L12.4111 11.5029ZM9.97382 9.42494L7.57105 7.40211L6.07147 8.99074L8.5294 11.0687C8.02265 11.3582 7.42626 11.5029 6.75749 11.5029C4.64429 11.5029 3.2171 9.94874 3.2171 7.65712C3.2171 5.32759 4.60981 3.81133 6.75749 3.81133C8.9086 3.81133 10.2979 5.32759 10.2979 7.65712C10.2979 8.32565 10.191 8.92182 9.97382 9.42494Z"
      fill={color}
    />
    <path
      d="M18.7162 14.246C20.0537 14.246 21.1017 13.8497 21.8601 13.0536H21.8946V13.9737H24.9282V4.4075H21.805V9.28365C21.805 10.5828 20.9018 11.4512 19.547 11.4512C18.1026 11.4512 17.451 10.7482 17.451 9.28365V4.4075H14.3278V9.95219C14.3278 12.6022 16.0238 14.246 18.7162 14.246Z"
      fill={color}
    />
    <path
      d="M31.161 14.3183C33.1121 14.3183 34.5738 13.7394 35.8941 12.5126L34.1601 10.5277C33.2225 11.4133 32.2641 11.8647 31.2713 11.8647C29.7545 11.8647 28.834 11.2134 28.7444 10.1314H36.456V9.21129C36.456 6.30282 34.198 4.06634 31.0748 4.06634C28.0239 4.0112 25.6005 6.34073 25.6556 9.21129C25.6487 12.2955 27.8171 14.3183 31.161 14.3183ZM31.1058 6.42688C32.4227 6.42688 33.2914 7.04028 33.4189 8.07064H28.7582C28.8857 7.07818 29.8234 6.42688 31.1058 6.42688Z"
      fill={color}
    />
    <path
      d="M40.2481 8.68404C40.2481 7.52961 41.0237 6.78871 42.182 6.78871C43.3368 6.78871 44.078 7.52961 44.078 8.68404V13.9737H46.9496V8.68404C46.9496 7.52961 47.7253 6.78871 48.8836 6.78871C50.0384 6.78871 50.7796 7.52961 50.7796 8.68404V13.9737H53.8132V8.21538C53.8132 5.85139 51.9 4.13526 49.3352 4.13526C48.0907 4.13526 46.9151 4.58669 46.1016 5.43442H46.0671C45.3983 4.62115 44.2435 4.13526 43.0335 4.13526C41.8235 4.13526 40.7755 4.54878 40.124 5.28968H40.0895V4.40405H37.2179V13.9737H40.2515V8.68404H40.2481Z"
      fill={color}
    />
    <path
      d="M65.048 1.33707H59.5219V13.9772H65.0135C66.9819 13.9772 68.5539 13.4361 69.7087 12.3713C70.8636 11.3065 71.4427 9.73509 71.4427 7.65712C71.4427 3.57355 68.8952 1.33707 65.048 1.33707ZM64.7963 11.1755H62.7383V4.13526H64.7963C67.0543 4.13526 68.2298 5.25522 68.2298 7.65712C68.2264 10.1107 67.0888 11.1755 64.7963 11.1755Z"
      fill={color}
    />
    <path d="M75.4726 0H72.3494V2.92569H75.4726V0Z" fill={color} />
    <path d="M75.4726 4.4075H72.3494V13.9772H75.4726V4.4075Z" fill={color} />
    <path
      d="M93.1263 8.01895C91.0303 7.69503 90.5787 7.52961 90.5787 7.09886C90.5787 6.68189 91.1579 6.43033 92.1679 6.43033C93.3952 6.43033 94.3914 6.97135 94.7327 7.78462L97.5492 6.95412C97.0079 5.2759 94.8051 4.06634 92.292 4.06634C89.4756 4.06634 87.5968 5.31381 87.5968 7.154C87.5968 8.88736 88.9516 9.77299 92.3299 10.2761C94.2087 10.5656 94.7844 10.7827 94.7844 11.2686C94.7844 11.6855 94.0605 11.9199 92.816 11.9199C91.3164 11.9199 90.3064 11.3409 89.9996 10.2589L87.3038 11.5132C87.8278 13.1639 89.8755 14.3218 92.6919 14.3218C95.8703 14.3218 97.8215 13.1294 97.8215 11.1962C97.8215 9.44217 96.4667 8.57721 93.1263 8.01895Z"
      fill={color}
    />
    <path
      d="M103.561 4.06289C100.507 4.00775 98.087 6.33728 98.1421 9.20784C98.1421 12.2955 100.31 14.3183 103.651 14.3183C105.602 14.3183 107.064 13.7394 108.384 12.5126L106.65 10.5277C105.712 11.4133 104.754 11.8647 103.761 11.8647C102.244 11.8647 101.324 11.2134 101.231 10.1314H108.943V9.21129C108.943 6.30282 106.685 4.06289 103.561 4.06289ZM101.248 8.07064C101.376 7.07818 102.313 6.42688 103.596 6.42688C104.913 6.42688 105.781 7.04028 105.909 8.07064H101.248Z"
      fill={color}
    />
    <path
      d="M109.66 13.9772H110.87C110.87 14.8249 110.618 15.1523 109.766 15.3487V16.8822C111.88 16.7202 113 15.5279 113 13.5223V10.9033H109.66V13.9772Z"
      fill={color}
    />
    <path
      d="M10.515 24.8322V24.7978C11.3114 24.2912 11.7974 23.4435 11.7974 22.4338C11.7974 20.4282 10.1703 19.0222 7.8951 19.0222H0.724701V31.6623H7.28148C10.6219 31.6623 12.249 30.3631 12.249 27.7441C12.249 26.4381 11.5975 25.356 10.515 24.8322ZM3.88588 21.7239H7.3194C8.16743 21.7239 8.60179 22.103 8.60179 22.8059C8.60179 23.5468 8.16743 23.9087 7.3194 23.9087H3.88588V21.7239ZM7.60552 28.9468H3.88588V26.3657H7.60552C8.56387 26.3657 9.07063 26.8171 9.07063 27.6304C9.07063 28.516 8.61903 28.9468 7.60552 28.9468Z"
      fill={color}
    />
    <path
      d="M18.2025 21.7446C15.1517 21.6894 12.7282 24.019 12.7834 26.8895C12.7834 29.9772 14.9517 32 18.2922 32C20.2433 32 21.705 31.4211 23.0253 30.1943L21.2913 28.2093C20.3537 29.095 19.3953 29.5464 18.4025 29.5464C16.8857 29.5464 15.9652 28.8951 15.8756 27.8131H23.5872V26.893C23.5872 23.981 21.3292 21.7446 18.2025 21.7446ZM15.8928 25.7523C16.0204 24.7599 16.9581 24.1086 18.2405 24.1086C19.5608 24.1086 20.4261 24.7219 20.5536 25.7523H15.8928Z"
      fill={color}
    />
    <path
      d="M30.1268 21.8893C28.9168 21.8893 27.9963 22.2684 27.3276 23.0093H27.2931V22.0892H24.3491V31.6588H27.4723V27.4891C27.4723 25.6662 28.3204 24.7254 29.8579 24.7254C30.2922 24.7254 30.7783 24.7805 31.3023 24.9046L31.8435 22.1236C31.2851 21.9582 30.7059 21.8893 30.1268 21.8893Z"
      fill={color}
    />
    <path
      d="M37.0834 21.7446C34.0291 21.6894 31.6091 24.019 31.6643 26.8895C31.6643 29.9772 33.8326 32 37.1731 32C39.1242 32 40.5859 31.4211 41.9062 30.1943L40.1722 28.2093C39.2346 29.095 38.2762 29.5464 37.2834 29.5464C35.7666 29.5464 34.8461 28.8951 34.7531 27.8131H42.4647V26.893C42.4647 23.981 40.2067 21.7446 37.0834 21.7446ZM34.7703 25.7523C34.8978 24.7599 35.8355 24.1086 37.1179 24.1086C38.4348 24.1086 39.3035 24.7219 39.431 25.7523H34.7703Z"
      fill={color}
    />
    <path
      d="M49.4972 21.8169C48.2527 21.8169 47.0944 22.2305 46.2808 22.9714H46.2464V22.0857H43.23V31.6554H46.3532V26.7103C46.3532 25.4112 47.3116 24.5428 48.756 24.5428C50.2177 24.5428 51.0312 25.3181 51.0312 26.7103V31.6588H54.1545V26.3657C54.1545 23.6571 52.2964 21.8169 49.4972 21.8169Z"
      fill={color}
    />
    <path d="M58.112 17.6817H54.9887V20.6074H58.112V17.6817Z" fill={color} />
    <path d="M58.112 22.0857H54.9887V31.6554H58.112V22.0857Z" fill={color} />
    <path
      d="M64.293 24.4704C65.4306 24.4704 66.227 25.0666 66.5165 26.059L69.4261 25.1734C68.8848 23.1506 66.7889 21.7411 64.2965 21.7411C61.2077 21.7411 58.8428 23.9431 58.8428 26.8688C58.8428 29.7945 61.2077 31.9966 64.2965 31.9966C66.7889 31.9966 68.8848 30.5871 69.4261 28.5643L66.5165 27.6787C66.2097 28.6883 65.4341 29.2673 64.293 29.2673C62.921 29.2673 61.9626 28.2921 61.9626 26.8654C61.9626 25.4284 62.921 24.4704 64.293 24.4704Z"
      fill={color}
    />
    <path
      d="M75.3313 21.7446C72.277 21.6894 69.857 24.019 69.9121 26.8895C69.9121 29.9772 72.0805 32 75.4209 32C77.3721 32 78.8337 31.4211 80.1541 30.1943L78.4201 28.2093C77.4824 29.095 76.5241 29.5464 75.5312 29.5464C74.0144 29.5464 73.094 28.8951 73.0009 27.8131H80.716V26.893C80.7125 23.981 78.4546 21.7446 75.3313 21.7446ZM73.0181 25.7523C73.1457 24.7599 74.0834 24.1086 75.3658 24.1086C76.6826 24.1086 77.5514 24.7219 77.6789 25.7523H73.0181Z"
      fill={color}
    />
    <path
      d="M86.1731 18.6741C82.8671 18.6741 80.6815 20.5695 80.5367 23.44L83.66 23.6916C83.7496 22.2649 84.7252 21.3793 86.1696 21.3793C87.3072 21.3793 88.0656 21.8479 88.0656 22.5716C88.0656 23.1299 87.6485 23.4572 86.08 24.1602C84.6528 24.7909 84.0943 25.4766 84.0943 26.507V27.4995H86.966V27.1549C86.966 26.5966 87.2555 26.3244 88.4276 25.835C90.2512 25.1114 91.282 24.0844 91.282 22.5475C91.2854 20.3351 89.0998 18.6741 86.1731 18.6741Z"
      fill={color}
    />
    <path d="M87.2762 28.4609H83.8634V31.6588H87.2762V28.4609Z" fill={color} />
    <path
      d="M81.7226 11.9164C80.223 11.9164 79.213 11.3375 78.9061 10.2554L76.2104 11.5098C76.8274 13.3776 78.782 14.3183 81.5985 14.3183C84.7769 14.3183 86.7281 13.126 86.7281 11.1928C86.7281 9.44217 85.3733 8.57377 82.0329 8.01551C79.9369 7.69158 79.4853 7.52617 79.4853 7.09541C79.4853 6.67844 80.0644 6.42688 81.0745 6.42688C82.3017 6.42688 83.2946 6.96791 83.6393 7.78118L86.4557 6.95068C85.9145 5.27245 83.7117 4.06289 81.1986 4.06289C78.3821 4.06289 76.5034 5.31036 76.5034 7.15055C76.5034 8.88391 77.8582 9.76955 81.2365 10.2727C83.1153 10.5621 83.691 10.7792 83.691 11.2651C83.691 11.6821 82.9671 11.9164 81.7226 11.9164Z"
      fill={color}
    />
  </svg>
)
export default QbdLogo
