import { Input } from '@grupoboticario/flora-react-input'
import { Text } from '@grupoboticario/flora-react-text'
import AddInfoButton from 'components/atoms/AddInfoButton'
import Overlay from 'components/molecules/Overlay'
import { ChangeEvent, useState } from 'react'
import { useStore } from 'utils'

const CalloutText = () => {
  const [open, setOpen] = useState(false)
  const [currentText, setCurrentText] = useState('')
  const [newText, setNewText] = useState('')

  const { hideUi } = useStore()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewText(event.target.value)
  }

  const handleConfirm = () => {
    setOpen(false)
    setCurrentText(newText)
  }

  const handleClose = () => {
    setOpen(false)
    setNewText(currentText)
  }

  return (
    <>
      <Overlay open={open} onClose={handleClose} onConfirm={handleConfirm}>
        <Input
          id="callout-text"
          labelText="Digite seu texto aqui"
          value={newText}
          onChange={handleChange}
        />
      </Overlay>

      {Boolean(currentText) ? (
        <Text
          as="p"
          css={{ cursor: hideUi ? 'default' : 'pointer', fontWeight: 700, fontSize: 28 }}
          {...(hideUi ? null : { onClick: () => setOpen(true) })}>
          {currentText.toUpperCase()}
        </Text>
      ) : (
        <AddInfoButton
          label="adicionar texto"
          title="Toque para adicionar um texto"
          onClick={() => setOpen(true)}
        />
      )}
    </>
  )
}

export default CalloutText
