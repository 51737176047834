const OuiLogo = ({ color }: { color: string }) => (
  <svg width="104" height="32" viewBox="0 0 104 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M102.693 1.30859C101.828 0.426467 100.774 0.000517951 99.4871 0.000517951C98.8941 -0.00852811 98.3053 0.101001 97.7553 0.322651C97.2054 0.5443 96.7054 0.873596 96.2847 1.29115C95.8641 1.70871 95.5313 2.20609 95.3061 2.75401C95.0808 3.30194 94.9676 3.88933 94.973 4.48163C94.9577 5.06683 95.0638 5.64887 95.2844 6.1912C95.5051 6.73353 95.8357 7.22443 96.2553 7.63308C97.1069 8.4821 98.1904 8.91381 99.4727 8.91381C100.755 8.91381 101.83 8.49362 102.707 7.6302C103.538 6.78657 104.003 5.64982 104 4.46644C103.997 3.28306 103.528 2.14844 102.693 1.30859Z"
      fill={color}
    />
    <path
      d="M16.1473 0.0422313C11.5583 0.0422313 7.79051 1.57335 4.64232 4.72192C1.56185 7.79711 0 11.6191 0 16.0902C0 20.5252 1.54744 24.3199 4.59909 27.3678C7.65075 30.4156 11.515 31.9611 16.1055 31.9611C20.6959 31.9611 24.5602 30.4156 27.6551 27.3663C30.7773 24.248 32.2945 20.5454 32.2945 16.0484C32.2945 11.5515 30.7629 7.83308 27.6119 4.72912C24.504 1.57191 20.7579 0.0422313 16.1473 0.0422313ZM22.9522 22.9601C21.1224 24.7876 18.833 25.7143 16.1501 25.7143C13.4962 25.7143 11.2225 24.7876 9.3927 22.9601C7.56142 21.0893 6.63353 18.7581 6.63353 16.0398C6.63353 13.2913 7.56142 10.9486 9.38982 9.07784C11.2182 7.20711 13.4803 6.27751 16.1501 6.27751C18.7926 6.27751 21.0489 7.22006 22.8687 9.07784C24.6798 10.8867 25.6221 13.228 25.6668 16.0341C25.7085 18.7409 24.7922 21.0721 22.9522 22.9601Z"
      fill={color}
    />
    <path
      d="M53.0726 28.8874C55.5133 30.9812 58.4656 32 62.0979 32C65.772 32 68.7256 30.9826 71.1189 28.8888C73.5121 26.7951 74.6805 23.7573 74.6805 19.6057V1.53451H68.1364V19.2992C68.1364 23.3285 65.8786 25.7259 62.0979 25.7259C58.2307 25.7259 56.0133 23.3832 56.0133 19.2992V1.53451H49.4691V19.6043C49.4691 23.7688 50.6477 26.7994 53.0726 28.8874Z"
      fill={color}
    />
    <path d="M102.758 10.193H96.2136V31.8733H102.758V10.193Z" fill={color} />
    <path
      d="M40.8571 28.4154C40.6729 28.2259 40.4512 28.0768 40.2061 27.9777C39.961 27.8786 39.6979 27.8316 39.4336 27.8398C39.1711 27.8358 38.9105 27.8842 38.667 27.9821C38.4235 28.08 38.202 28.2256 38.0156 28.4101C37.8292 28.5947 37.6816 28.8146 37.5814 29.057C37.4812 29.2993 37.4305 29.5592 37.4323 29.8213C37.4254 30.0809 37.4724 30.339 37.5703 30.5795C37.6683 30.82 37.8151 31.0376 38.0014 31.2186C38.3882 31.5836 38.9001 31.7869 39.4321 31.7869C39.9642 31.7869 40.4761 31.5836 40.8629 31.2186C41.2322 30.8451 41.4392 30.3413 41.4392 29.8163C41.4392 29.2913 41.2322 28.7875 40.8629 28.414L40.8571 28.4154Z"
      fill={color}
    />
    <path
      d="M86.138 28.4153C85.9541 28.2259 85.7326 28.0769 85.4877 27.9778C85.2429 27.8787 84.98 27.8317 84.7159 27.8397C84.4534 27.8357 84.1927 27.8841 83.9491 27.982C83.7055 28.0799 83.484 28.2254 83.2975 28.41C83.1109 28.5945 82.9632 28.8144 82.8628 29.0568C82.7625 29.2991 82.7116 29.559 82.7132 29.8213C82.7072 30.0813 82.7553 30.3398 82.8546 30.5803C82.9538 30.8208 83.1019 31.0382 83.2895 31.2185C83.6763 31.5835 84.1882 31.7868 84.7203 31.7868C85.2523 31.7868 85.7642 31.5835 86.151 31.2185C86.521 30.8454 86.7285 30.3414 86.7285 29.8162C86.7285 29.291 86.521 28.7871 86.151 28.4139L86.138 28.4153Z"
      fill={color}
    />
  </svg>
)

export default OuiLogo
