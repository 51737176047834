import { Button, IconButton } from '@grupoboticario/flora-react-button'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { Box } from '@grupoboticario/flora-react-box'

export interface OverlayProps {
  children: React.ReactNode
  open: boolean
  onClose: () => void
  onConfirm?: () => void
}

const Overlay = ({ children, open, onClose, onConfirm }: OverlayProps) => (
  <Dialog
    fullScreen
    open={open}
    sx={{
      '& .MuiPaper-root': { color: '#FFFFFF', backgroundColor: 'rgba(0, 0, 0, 0.75)' },
    }}>
    <DialogTitle>
      <Box
        css={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <IconButton aria-label="Fechar" onClick={onClose}>
          <CloseIcon />
        </IconButton>

        {Boolean(onConfirm) ? (
          <Button onClick={onConfirm} variant="ghost-alt" size="small">
            Concluir
          </Button>
        ) : null}
      </Box>
    </DialogTitle>

    <DialogContent sx={{ padding: 4 }}>{children}</DialogContent>
  </Dialog>
)

export default Overlay
