import axios, { AxiosResponse } from 'axios'

interface IHttp {
  [key: string]: (path: string, data: Record<string, unknown>) => Promise<AxiosResponse>
  get: (path: string, data?: Record<string, unknown>) => Promise<AxiosResponse>
  post: (path: string, data: Record<string, unknown>) => Promise<AxiosResponse>
  put: (path: string, data: Record<string, unknown>) => Promise<AxiosResponse>
  patch: (path: string, data: Record<string, unknown>) => Promise<AxiosResponse>
  delete: (path: string, data: Record<string, unknown>) => Promise<AxiosResponse>
}

const createInstance = (): IHttp => {
  const API = axios.create({
    baseURL: `${process.env.REACT_APP_GB_CARDS_SERVICE_BASE_URL}/api`,
  })

  return {
    get: (path: string, data?: Record<string, unknown>): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        API.get(path, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    post: (path: string, data: Record<string, unknown>): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        API.post(path, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    put: (path: string, data: Record<string, unknown>): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        API.put(path, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete: (path: string, data: Record<string, unknown>): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        API.delete(path, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patch: (path: string, data: Record<string, unknown>): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        API.patch(path, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}

export default createInstance()
