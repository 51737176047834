import { pushToDataLayer } from 'utils/helpers'

export const tagProvider = {
  tagEvent(eventData: unknown) {
    pushToDataLayer(eventData)
  },
  pageView(pathname: string, pageTitle: string) {
    const domain = window.location.origin.replace(/\/$/, '')

    this.tagEvent({
      event: 'page_view',
      event_parameters: {
        page_location: `${domain}/${pathname}`,
        page_title: pageTitle,
      },
    })
  },
}
