import { Box } from '@grupoboticario/flora-react-box'
import { Text } from '@grupoboticario/flora-react-text'

export interface ProductDTO {
  imageUrl: string
  name: string
  price: { price: number; discount: number }
}

const brlFormat = (number: number) =>
  Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)

const Product = ({ imageUrl, name, price }: ProductDTO) => (
  <Box
    css={{
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
    <Box css={{ marginBottom: 8 }}>
      <img src={imageUrl} alt={name} style={{ borderRadius: 4 }} />
    </Box>

    <Box css={{ marginBottom: 4 }}>
      <Text size="auxiliarSmall" as="p" css={{ textAlign: 'center' }}>
        {name}
      </Text>
    </Box>

    <Text size="bodyMediumStandard">{brlFormat(price.price)}</Text>
  </Box>
)

export default Product
