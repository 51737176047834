import slugify from 'react-slugify'
import { ChangeEvent, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Input } from '@grupoboticario/flora-react-input'
import { Toaster } from '@grupoboticario/flora-react-toast'

import { Search as SearchIcon } from '@mui/icons-material'

import { getProduct, searchProduct } from 'api'
import { pushToDataLayer, useStore } from 'utils'
import { BrandNameGTM } from 'common/enums'
import { IProduct, IProducts } from 'common/types/ProductType'

import AddProductButton from 'components/atoms/AddProductButton'
import Overlay from 'components/molecules/Overlay'
import { Box } from '@grupoboticario/flora-react-box'
import { Text } from '@grupoboticario/flora-react-text'
import { Grid } from '@grupoboticario/flora-react-grid'
import { Spinner } from '@grupoboticario/flora-react-spinner'
import PriceControl from 'components/molecules/PriceControl'
import Product from 'components/molecules/Product'
import useSocket from './useSocket'

const AddProduct = () => {
  const [open, setOpen] = useState(false)
  const [productName, setProductName] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null)
  const [result, setResult] = useState<IProducts>({ items: [], page: 1, size: 0, total: 0 })

  const { imgSrc, error, loadingImage } = useSocket({
    product: selectedProduct,
  })

  const { brand, uf, hideUi } = useStore()

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setProductName(event.target.value)
  }

  const handleClick = async (product: IProduct) => {
    setOpen(false)
    setLoadingProduct(true)
    try {
      const { data } = await getProduct({
        partnerSku: product.partnerSku,
        business_unit: brand as string,
        state: uf?.key as string,
      })
      setSelectedProduct(data)

      pushToDataLayer({
        event: 'event',
        eventCategory: `gbcards:vd:${BrandNameGTM[brand as keyof typeof BrandNameGTM]}`,
        eventAction: 'click:button:selecionar-produto',
        eventLabel: `${slugify(data.name)}:${process.env.REACT_APP_GB_CARDS_BASE_URL}/selecionar-produto`,
      })
    } catch (error) {
      Toaster.notify({
        kind: 'error',
        description: 'Erro ao buscar o produto. Tente novamente.',
        origin: 'right-bottom',
      })
    } finally {
      setLoadingProduct(false)
    }
  }

  useEffect(() => {
    const handleSearchProduct = debounce(async () => {
      setLoadingSearch(true)
      try {
        const { data } = await searchProduct({
          q: productName,
          business_unit: brand as string,
          state: uf?.key as string,
        })
        setResult(data)
      } catch (error) {
        Toaster.notify({
          kind: 'error',
          description: 'Erro ao buscar o produto. Tente novamente.',
          origin: 'right-bottom',
        })
      } finally {
        setLoadingSearch(false)
      }
    }, 600)

    if (productName) {
      handleSearchProduct()
    }

    return () => handleSearchProduct.cancel()
  }, [brand, productName, uf?.key])

  return (
    <>
      <Overlay open={open} onClose={() => setOpen(false)}>
        <Input
          required
          id="product-search"
          labelText="Busque um produto"
          rightIcon={<SearchIcon />}
          onChange={handleChangeInput}
          css={{ marginBottom: 32 }}
        />

        {Boolean(result.items.length && !loadingSearch) && (
          <Grid as="ul" gapX={48} gapY={24} templateColumns="1fr 1fr">
            {result.items.map(item => (
              <li key={item.partnerSku} onClick={() => handleClick(item)}>
                <Product
                  imageUrl={item?.imageObjects[0]?.small as string}
                  name={item.name}
                  price={item.price}
                />
              </li>
            ))}
          </Grid>
        )}

        {loadingSearch && (
          <Box css={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner size="md" />
          </Box>
        )}
      </Overlay>

      {Boolean(loadingProduct) ? (
        <Box
          css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 350 }}>
          <Spinner />
        </Box>
      ) : Boolean(selectedProduct) ? (
        <>
          <Box css={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
            <Box css={{ width: '50%', marginRight: 12 }}>
              <PriceControl
                originalAmount={selectedProduct?.price.price.toFixed(2) as string}
                discount={selectedProduct?.price.discount.toFixed(2) as string}
              />
            </Box>
          </Box>

          <Box
            css={{ cursor: hideUi ? 'default' : 'pointer' }}
            {...(hideUi ? null : { onClick: () => setOpen(true) })}>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: 350,
              }}>
              {loadingImage ? (
                <Box
                  css={{
                    padding: 16,
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 348,
                  }}>
                  {error
                    ? 'Houve um erro ao carregar a imagem do produto. Por favor, tente novamente.'
                    : `Estamos preparando a imagem desse produto. Isso pode levar uns minutinhos, mas
                  você pode editar o resto do seu card enquanto espera! 😉`}
                </Box>
              ) : (
                <img
                  src={imgSrc}
                  alt={selectedProduct?.name}
                  style={{ width: '100%', borderRadius: 4 }}
                />
              )}

              <Box css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box
                  css={{
                    padding: 8,
                    backgroundColor: '#FFF',
                    color: '#000',
                    margin: '4px 12px',
                  }}>
                  <Text css={{ fontSize: 11 }} as="p" align="center">
                    {selectedProduct?.partnerSku}
                  </Text>
                </Box>

                <Box css={{ padding: 8, backgroundColor: '#FFF', color: '#000', margin: '0 12px' }}>
                  <Text css={{ fontSize: 11 }} as="p" align="center">
                    {selectedProduct?.name}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <AddProductButton onClick={() => setOpen(true)} />
      )}
    </>
  )
}

export default AddProduct
