import { AxiosResponse } from 'axios'
import { http } from 'utils'

const baseUrl = 'https://nominatim.openstreetmap.org/'
const reverseSearchPath = `${baseUrl}/reverse`

export const getLocation = (latitude: number, longitude: number): Promise<AxiosResponse> =>
  http.get(reverseSearchPath, {
    params: { format: 'json', lat: latitude, lon: longitude },
  })
