import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Spinner } from '@grupoboticario/flora-react-spinner'
import { Box } from '@grupoboticario/flora-react-box'

import { pushToDataLayer, useGeolocation, useQuery, useStore } from 'utils'
import { ISelectedUf } from 'components/molecules/UfSelectionDialog/UfSelectionDialog'
import CreateNewCard from 'components/molecules/CreateNewCard'
import UfConfirmationDialog from 'components/molecules/UfConfirmationDialog'
import UfSelectionDialog from 'components/molecules/UfSelectionDialog'
import { BrandCode, BrandNameGTM } from 'common/enums'
import { slugifyStateFromUF } from 'common/helpers/stateName'

const Home = () => {
  const { handleGetLocation, isLoading, location, error } = useGeolocation()
  const { updateStore } = useStore()

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [openSelectionDialog, setOpenSelectionDialog] = useState(false)

  const query = useQuery()
  const history = useHistory()

  const handleCreateCard = () => {
    handleGetLocation()
  }

  const handleConfirmUf = (uf: ISelectedUf) => {
    pushToDataLayer({
      event: 'event',
      eventCategory: 'gbcards:vd',
      eventAction: 'click:button:continuar',
      eventLabel: `${slugifyStateFromUF(uf.key)}:${process.env.REACT_APP_GB_CARDS_BASE_URL}/`,
    })
    updateStore({ uf })
    history.push('/selecionar-marca')
  }

  const handleSelectUf = () => {
    setOpenConfirmationDialog(false)
    setOpenSelectionDialog(true)
  }

  useEffect(() => {
    if (location) {
      setOpenConfirmationDialog(true)
    }
    if (error) {
      setOpenConfirmationDialog(false)
      setOpenSelectionDialog(true)
    }

    const brand = query.get('brand')
    if (brand) {
      if (brand === BrandCode.bot || brand === BrandCode.eud) {
        pushToDataLayer({ chosenBrand: BrandNameGTM[brand] })
        updateStore({ brand, fromApp: true })
      } else {
        history.push('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, location])

  return (
    <>
      {location && (
        <UfConfirmationDialog
          open={openConfirmationDialog}
          uf={location.uf}
          ufName={location.state}
          onConfirm={handleConfirmUf}
          onCancel={handleSelectUf}
        />
      )}

      <UfSelectionDialog open={openSelectionDialog} onConfirm={handleConfirmUf} />

      <Box
        css={{
          px: 12,
          height: '100vh',
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#AAD1DA',
        }}>
        {isLoading ? <Spinner size="md" /> : <CreateNewCard onClick={handleCreateCard} />}
      </Box>
    </>
  )
}

export default Home
