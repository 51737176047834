import { BrandCode, DomainBrandUrl } from 'common/enums'

export const chosenDomainMap = {
  [BrandCode.bot]: DomainBrandUrl.BOTICARIO,
  [BrandCode.eud]: DomainBrandUrl.EUDORA,
  [BrandCode.oui]: DomainBrandUrl.OUI,
  [BrandCode.qdb]: DomainBrandUrl.EAM,
}

export type UrlMountParams = { chosenBrand: BrandCode; resellerId?: string }

export const urlMountingByChosenBrandUseCase = ({ chosenBrand, resellerId }: UrlMountParams) => {
  const genericReseller = chosenBrand === 'eudora' ? 'representante' : 'revendedor'
  const domain = chosenDomainMap[chosenBrand]
  const reseller = resellerId ? resellerId : genericReseller
  return `https://minhaloja.${domain}/redirect/${reseller}/?utm_source=vdcards`
}

export const shareableTextWithUrlUseCase = ({ chosenBrand, resellerId }: UrlMountParams) => {
  const url = urlMountingByChosenBrandUseCase({ chosenBrand, resellerId })
  return `Oi, tudo bem? Veja os produtos e faça seu pedido pela Minha Loja Digital!\nAcesse: ${url}`
}
