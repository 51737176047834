import { AxiosResponse } from 'axios'
import { IProduct, IProducts, IProductSearch } from 'common/types/ProductType'
import { http } from 'utils'

const basePath = '/product'

export const searchProduct = (params: IProductSearch): Promise<AxiosResponse<IProducts>> =>
  http.get(basePath, { params })

export const getProduct = (params: {
  partnerSku: string
  business_unit: string
  state: string
}): Promise<AxiosResponse<IProduct>> =>
  http.get(`${basePath}/${params.partnerSku}`, {
    params: { business_unit: params.business_unit, state: params.state },
  })

export const urlContentToDataUri = (url: string) =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise(callback => {
          const reader = new FileReader()
          reader.onload = function () {
            callback(this.result)
          }
          reader.readAsDataURL(blob)
        })
    )
